// WEB-CORE
import HttpService from '@rca/vue-core/shell/services/HttpServiceProto'
import FS from '@wsi/web-core/services/FederationService'

// INTERNAL
import { serviceContainerSingleton } from './utils/ServiceContainerSingleton'
import TrackingService from '@rca/vue-core/shell/services/TrackingService'
import _LogService from '@rca/vue-core/shell/services/LogService'

const LogService = _LogService()
LogService.addWindowListeners()

// service container
const serviceContainer = serviceContainerSingleton.getInstance()

let cfg = null
fetch('/cfg')
  .then((res) => {
    return res.json()
  })
  .then((data) => {
    cfg = data
    serviceContainer.setContainerConfig(cfg)
    serviceContainer.registerServices({
      FederationService: new FS(cfg),
      SharedHttpService: new HttpService({
        defaultUrl: cfg.B2FE_BASE_URL,
        defaultHeaders: {
          'Content-Type': 'application/json'
        }
      }),
      TrackingService: new TrackingService(
        { apiUrl: cfg.B2FE_BASE_URL, appName: 'RCA_QUICK_CHECKOUT', ENABLE_TRACKING: cfg.ENABLE_TRACKING, apiKey: cfg.API_KEY }
      ),
      LogService
    })
  })
  .catch((error) => {
    LogService.error(error)
  })
  .finally(() => {
    import('./bootstrap')
  })
